.userHoroscopePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 57px 84px 0 84px;

  .wrapper {
    min-width: 1272px;
    width: 100%;
  }

  .tableHeader {
    color: #556384;
    padding: 16px 16px 16px 0px;

    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    border: none;
  }

  .tableBody {
    padding: 16px 16px 16px 0px;
    color: #c4c4c4;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;

    border: none;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
  }

  .tableBodyId {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.49px;
    padding: 16px 16px 16px 0px;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;

    border: none;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
  }

  .headerFirst {
    min-width: 89px;
  }

  .headerSecond {
    min-width: 119px;
  }

  .headerThree {
    min-width: 167px;
  }

  .headerFour {
    min-width: 47px;
  }

  .headerFive {
    min-width: 39px;
  }

  .headerSix {
    min-width: 20px;
  }

  .headerSeven {
    min-width: 20px;
  }

  .headerEight {
    min-width: 20px;
  }

  .headerNine {
    min-width: 37px;
  }

  .horoscopeName {
    color: #fff;
    font-family: Gilroy;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.91px;
  }

  .secondHeader {
    margin-top: 32px;
    display: inline-flex;
    align-items: flex-start;
    gap: 16px;
  }

  .secondTableHeader {
    color: #556384;

    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.035em;
    text-align: left;
  }

  .secondTableHeaderImg {
    padding-right: 16px;
    border-right: 1px solid rgb(36, 42, 53);
  }

  .tableContainer {
    color: #181b22;
  }

  .classTableRow {
    padding: 16px 16px 16px 0px;
    color: #556384;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.44px;
    border-bottom: none;
    min-width: 210px;
  }

  .cursorActive {
    color: #ffffff;
    border-bottom: 1px solid rgb(36, 42, 53);
  }

  .cursorActiveText {
    color: #c4c4c4;
    border-bottom: 1px solid rgb(36, 42, 53);
  }

  .tableHeaderID {
    vertical-align: top;
    padding: 16px 16px 16px 0px;
    min-width: 97px;
    color: #fff;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.49px;
    border-bottom: 1px solid rgb(36, 42, 53);
    min-width: 210px;
  }

  .tableHeaderFirst {
    padding: 16px 16px 16px 0px;
    min-width: 225px;
    color: #c4c4c4;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
    min-width: 252px;
  }

  .tableHeaderSecond {
    padding: 16px 16px 16px 0px;
    min-width: 214px;
    color: #c4c4c4;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
    min-width: 254px;
  }

  .tableHeaderThree {
    padding: 16px 16px 16px 0px;
    min-width: 215px;
    color: #c4c4c4;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
    min-width: 254px;
  }

  .tableHeaderFour {
    padding: 16px 0px 0px 0px;
    min-width: 220px;
    color: #c4c4c4;

    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
    min-width: 252px;
  }

  .tableBody {
    padding: 16px 16px 16px 0px;
    color: #c4c4c4;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;

    border-bottom: 1px solid rgb(36, 42, 53);
  }

  .paginationColors {
    color: #556384;
  }

  .secondTableHeader {
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.56px;

    cursor: pointer;

    a.active {
      color: #fff;
    }
  }
}

.paginationHolder {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .pagination {
    // flex-wrap: wrap;
    margin: 64px 0px;
    display: flex;
    align-items: center;

    .classTableBody {
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      vertical-align: top;
      letter-spacing: 0.42px;
    }

    .pageNumber {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 44px;
      height: 44px;
      text-align: center;
      color: var(--Gray, #556384);
      text-align: center;
      font-family: Gilroy;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.84px;
      background-color: transparent !important;
      cursor: pointer;
      overflow-y: scroll;
    }

    .activeItem {
      color: #fff !important;
    }
  }
}

// MuiTablePagination-displayedRows
