.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0 0 0;

  .contentWrapper {
    // width: 1680px;

    .horizontalMenu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;
      height: 44px;

      font-family: Gilroy;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.26px;
      color: var(--Gray, #556384);

      .link {
        cursor: pointer;
      }

      .searchField {
        align-self: flex-start;
      }
    }

    .pagination {
      margin: 64px 0 0 0;
      display: flex;
      justify-content: center;
      height: 56px;

      button {
        color: var(--Gray, #556384);
        text-align: center;
        font-family: Gilroy;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.84px;
      }
    }
  }
}
