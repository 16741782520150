.compatibilityPage {
  .tableHeader {
    color: #556384;
    padding: 16px 16px 16px 0px;

    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    border: none;
  }

  .tableHeaderFirst {
    min-width: 180px;
  }

  .tableHeaderSecond {
    min-width: 252px;
  }

  .tableHeaderThree {
    min-width: 254px;
  }

  .tableHeaderFour {
    min-width: 254px;
  }

  .tableHeaderFive {
    min-width: 252px;
  }

  .raw {
    display: flex;
  }

  .tableBody {
    padding: 16px 16px 16px 0px;
    color: #c4c4c4;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.42px;

    border: none;
    vertical-align: top;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
  }

  .tableBodyId {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.49px;
  }

  .buttons {
    display: flex;
    margin-left: auto;
    margin-top: 20px;
  }

  .buttonImport {
    width: 174px;
    height: 44px;
    border-radius: 12px;
    border: none;
    background: rgb(31, 35, 46);
    color: rgb(255, 255, 255);
    font-family: Gilroy;
    font-size: 14px;
    letter-spacing: 3.5%;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }

  .buttonAdd {
    display: flex;

    width: 140px;
    height: 44px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    border: none;
    background-color: #fff;

    margin-left: 12px;
    color: #181b22;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.5%;
  }

  .cursorActive {
    cursor: pointer;

    a.active {
      color: #fff;
    }
  }
}
