.editHoroscopePage {
  .container {
    display: flex;
  }

  .wrapper {
    margin-top: 64px;
    margin-left: 71px;
    margin-right: 114px;

    .containerInputs {
      display: flex;
      gap: 50px;
      margin-left: 13px;
    }
  }

  .mainIcon {
    margin-left: 13px;
  }

  .horoscopeId {
    color: #fff;

    font-family: Gilroy;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.91px;
    margin-top: 24px;
    margin-left: 13px;
  }

  .timeContainer {
    display: flex;

    .createTime {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 101px;
      color: #556384;

      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.44px;

      .timeTextcreate {
        color: #fff;

        font-size: 14px;
        letter-spacing: 0.49px;
      }
    }
  }

  .textField {
    width: 100%;
    margin-top: 8px;

    .field {
      display: flex;
      width: 100%;
      height: 450px;
      padding: 13px 16px 12px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      outline: none;
      border-radius: 12px;
      background: #1f232e;
      color: #c4c4c4;
      border-color: #1f232e;
      outline: none;
      resize: none;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.49px;
    }
  }

  .textHeader {
    color: #fff;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
  }

  .buttonsContainer {
    display: flex;
    margin-top: 40px;
    margin-left: 13px;

    .buttonSave {
      display: flex;
      width: 140px;
      height: 44px;
      padding: 12px 32px 16px 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: none;
      border-radius: 12px;
      background: #fff;
      font-weight: 600;
      color: #181b22;
      text-align: center;

      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
    
      line-height: normal;
      letter-spacing: 0.49px;
    }

    .buttonDelete {
      display: flex;
      width: 140px;
      height: 44px;
      padding: 12px 42px 16px 42px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: none;
      border-radius: 12px;
      background: #1f232e;
      margin-left: 12px;

      color: #fff;
      text-align: center;

      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.49px;

   

   
    }
  }
}

.label {
  padding: 0 0 0 14px;
  font-family: Gilroy;
  color: #556384;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.select {
  color: #fff !important;
  width: 155px;

  svg {
    color: #fff !important;
  }
}