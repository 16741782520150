.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 57px 84px 0 84px;

  .contentWrapper {
    width: 100%;
  }
}
