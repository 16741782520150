.pagination {
  margin: 64px 0 0 0;
  display: flex;
  justify-content: center;
  height: 56px;


  button {
    color: var(--Gray, #556384);
    text-align: center;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
    background-color: transparent !important;
  }

  // .table3 [class~='ant-table-cell'] {
  //   padding: 8px 6px 8px 16px !important;
  // }

  .activeItem {
    color: #fff !important;

  }
}
