.horizontalMenu {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  height: 31px;

  color: var(--Gray, #556384);
  font-family: GilroyBold;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.91px;



  .link {
    cursor: pointer;

    a.active {
      color: #fff;
    }
  }

  .searchField {
    align-self: flex-start;
  }
}