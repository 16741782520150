.reviewPage {
  .tableHeader {
    color: #556384;

    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    border: none;
  }

  .tableHeaderFirst {
    width: 474px;
  }

  .tableHeaderSecond {
    width: 240px;
  }

  .tableHeaderThree {
    width: 160px;
  }

  .tableHeaderFour {
    width: 220px;
  }

  .tableBody {
    color: #fff;

    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.8;
    border: none;
    text-align: justify;
    border-bottom: 1px solid rgb(36, 42, 53);
  }
}