body {
  margin: 0;
  background: var(--BG, #181b22);
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./fonts/Gilroy-Regular.woff) format('woff');
}

@font-face {
  font-family: 'GilroyBold';
  src: local('GilroyBold'), url(./fonts/Gilroy-Bold.woff) format('woff');
}

.active {
  color: #fff !important;
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* start user pagination */
.css-levciy-MuiTablePagination-displayedRows {
  display: none !important;
}

.MuiTablePagination-displayedRows {
  display: none !important;
}

.MuiTablePagination-root {
  border: none !important;
}

/* end user pagination */

/* select */
#demo-simple-select > div {
  display: none;
}

.MuiInputBase-root {
  color: #fff !important;
}
