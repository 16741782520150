.createAffirmations {
  .wrapper {
    margin-top: 64px;
    margin-left: 71px;
    margin-right: 84px;
  }

  .mainIcon {
    margin-left: 13px;
  }

  .categoryText {
    width: 75px;
    height: 20px;
    resize: none;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 80px;
    background: #1f232e;
    color: #c4c4c4;
    border-radius: 5px;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.49px;
    resize: none;
  }

  .timeContainer {
    display: flex;

    .createTime {
      margin-right: 101px;
      color: #556384;

      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.44px;
      text-transform: uppercase;

      .timeTextcreate {
        color: #fff;

        font-size: 14px;
        letter-spacing: 0.49px;
      }
    }

    .lastEditTime {
      color: #556384;

      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.44px;
      text-transform: uppercase;

      .timeTextEdit {
        color: #fff;

        font-size: 14px;
        letter-spacing: 0.49px;
      }
    }
  }

  .container {
    margin-left: 13px;
    display: flex;
    gap: 16px;
  }

  .textField {
    width: 100%;
    margin-top: 8px;

    .field {
      display: flex;
      width: 100%;
      height: 450px;
      padding: 13px 16px 12px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      box-sizing: border-box;
      min-height: 450px;
      border-radius: 12px;
      background: #1f232e;

      color: #c4c4c4;
      border-color: #1f232e;
      outline: none;
      resize: none;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.49px;
    }
  }

  .textHeader {
    color: #fff;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
  }

  .buttonsContainer {
    display: flex;
    margin-top: 40px;
    margin-left: 13px;
    margin-bottom: 64px;

    .buttonSave {
      display: flex;
      width: 140px;
      height: 44px;
      padding: 12px 32px 16px 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: none;
      border-radius: 12px;
      background: #fff;
      color: #181b22;
      text-align: center;

      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.49px;
    }

    .buttonDelete {
      display: flex;
      width: 140px;
      height: 44px;
      padding: 12px 42px 16px 42px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 12px;
      background: #1f232e;
      border: none;
      margin-left: 12px;
      color: #fff;
      text-align: center;

      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.49px;
    }
  }
}