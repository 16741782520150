.userPage {
  .tableHeader {
    color: #556384;
    padding: 16px 16px 16px 0px;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    border: none;
  }

  .tableBody {
    color: #fff;
    padding: 16px 16px 16px 0px;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.8;
    border-bottom: 1px solid rgb(36, 42, 53);
  }
}